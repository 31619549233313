import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3, P } from '@/components/Typography';
import viewport1Img from '../../assets/img/b2bHubVp1.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import SmallCard from '@/components/SmallCard';
import HowItWorksCard from '@/components/HowItWorksCard';
import CarouselCard from '@/components/CarouselCard';
import Banner from '@/components/Banner';
import bannerBg from '../../assets/img/b2bBannerBg.png';
import { B2bHubPageContent } from '@/pages/order-management';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(180deg, #ffffff 0%, #e7edf3 100%);
  @media (max-width: 1200px) {
    padding-bottom: 1rem;
  }
`;

const ThirdViewport = styled(Section)``;

const FourthViewport = styled(Section)``;

const FifthViewport = styled(Section)`
  background-color: #fff;
`;

const WhiteBgViewportWrapper = styled.div`
  background: #fff;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const CenteredH3 = styled(H3)`
  text-align: center;
`;

const Vp8CardsWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const StyledDescription = styled(Description)`
  font-weight: 500;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

const Vp2Description = styled(P)`
  margin-top: 0.5rem;
  width: 80%;
`;

const BenefitCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 1rem;
`;

interface ProductHubProps {
  content: B2bHubPageContent;
}

const B2bHubContainer: React.FunctionComponent<ProductHubProps> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3>{viewport2.title}</H3>
      <Vp2Description>{viewport2.description}</Vp2Description>
      <BenefitCardWrapper>
        {viewport2.benefitCards.map((item) => (
          <SmallCard
            key={item.title}
            title={item.title}
            text={item.description}
            cardImgSrc={item.imageSrc}
            flexBasis="12%"
          />
        ))}
      </BenefitCardWrapper>
    </SecondViewport>

    <WhiteBgViewportWrapper>
      <ThirdViewport>
        <H3>{viewport3.title}</H3>
        <Vp2Description>{viewport3.description}</Vp2Description>
        <Vp8CardsWrapper>
          {viewport3.viewport3Cards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.description}
              image={item.imageSrc}
              allImgCentered
              backgroundColor="#fff"
            />
          ))}
        </Vp8CardsWrapper>
      </ThirdViewport>
    </WhiteBgViewportWrapper>

    <FourthViewport>
      <CenteredH3>{viewport4.title}</CenteredH3>
      <CardWrapper>
        {viewport4.pipelineCards.map((item) => (
          <CarouselCard
            key={item.title}
            flexBasis="25%"
            marginBottom="2rem"
            title={item.title}
            text={item.description}
            cardImgSrc={item.imageSrc}
          />
        ))}
      </CardWrapper>
    </FourthViewport>

    <FifthViewport>
      <Banner
        bgSrc={bannerBg}
        label={viewport5.bannerLabel}
        description={viewport5.bannerDescription}
        buttonLabel={viewport5.button.label}
        buttonUrl={viewport5.button.url}
      />
    </FifthViewport>
  </>
);

export default B2bHubContainer;
