import React from 'react';
import Main from '../containers/Layout';
import b2bHubContent from '../../content/pages/order-management.yml';
import B2bHubContainer from '@/containers/toolkit/B2bHubContaner';

export interface B2bHubPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    description: string;
    benefitCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport3: {
    title: string;
    description: string;
    viewport3Cards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport4: {
    title: string;
    pipelineCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport5: {
    bannerLabel: string;
    bannerDescription: string;
    button: { label: string; url: string };
  };
}

const B2bHub: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5 } =
    b2bHubContent as unknown as B2bHubPageContent;
  return (
    <Main>
      <B2bHubContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
        }}
      />
    </Main>
  );
};

export default B2bHub;
